
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import TemplateSelect from "@/components/catalogs-select/TemplateSelect.vue";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import ApiService from "@/core/services/ApiService";
import vars from "@/core/data/vars";
import { VueEditor } from "vue3-editor";

interface FormCatalog {
  id?: number;
  name: string;
  body: string;
  category_name: string;
  template_types_id: string;
  office_ids: [];
}

export default {
  name: "EmailTemplatesForm",
  components: {
    Form,
    Field,
    TemplateSelect,
    OfficeSelect,
    VueEditor,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref(false);

    const dialogvisible = computed(() => store.getters.ArrivalNoticeDialog);
    const form = ref({
      id: null,
      template_types_id: null,
      name: "",
      body: "",
      category_name: "",
      office_ids: [] as any,
    });
    const tags = ref([] as any);
    const values = ref([] as any);

    const onChangeId = (value) => {
      form.value.template_types_id = value.id;
      const index = vars.findIndex(
        (x: any) => "name" in x && x.name === value.name
      );
      console.log("index");
      console.log(index);
      values.value = form.value.template_types_id ? vars[index].vars : [];
      tags.value = values.value.map((x) => x.label);
    };

    const createElement = async (payload, resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (await ApiService.post("/api/template", payload)).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (payload, resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put("/api/template/" + props.entityId, payload)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const testing = (tag: any) => {
      let temp_remove = form.value.body.replace("<p>", "");
      temp_remove = form.value.body.replace("</p>", "");
      const temp = `${temp_remove} ${tag}`;
      form.value.body = temp;
    };

    const onSubmit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const payload = form.value;
        if (form.value.id) {
          updateElement(payload, resetForm);
        } else {
          createElement(payload, resetForm);
        }
      }
    };

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/template/view/" + props.entityId).then(
          ({ data }) => {
            form.value.id = data.id;
            form.value.template_types_id = data.template_types_id;
            form.value.name = data.name;
            form.value.body = data.body;
            form.value.category_name = data.category_name;
            form.value.office_ids = data.offices.map((x) => x.id);
            onChangeId(data.template_type);
            store.commit("setLoadingStatus", false);
          }
        );
      }
    });

    return {
      loading,
      form,
      tags,
      dialogvisible,
      submitButton,
      testing,
      onSubmit,
      onChangeId,
    };
  },
};
