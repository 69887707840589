
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";

export default {
  name: "OfficeSelect",
  props: {
    office_id: {
      type: [Number, Array],
      default: undefined,
    },
    office_name: {
      type: [String, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    useroffices: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    datas: {
      type: Array,
      default: [] as any,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:office_id", "changeOffice"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const officeData = computed(() => store.getters.Office);
    const inputOffice = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isOfficeRequired = (value) => {
      if (props.required && !value) {
        return t("rofficeid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("office", isOfficeRequired);

    const getOffices = () => {
      if (props.load && !officeData.value.length) {
        ApiService.query("/api/offices", {
          params: {
            per_page: 10,
          },
        }).then(({ data }) => {
          store.commit("setOfficeList", []);
          store.commit("setOfficeList", data.offices);
          inputOffice.value.list = data.offices;
          inputOffice.value.options = data.offices;
          if (
            props.office_name !== undefined &&
            props.office_id !== undefined
          ) {
            inputOffice.value.options.push({
              id: props.office_id,
              name: props.office_name,
            });
          }
        });
      } else {
        inputOffice.value.list = officeData.value;
        inputOffice.value.options = officeData.value;
      }
    };
    const selectOffice = (query) => {
      if (query !== "") {
        inputOffice.value.loading = true;
        setTimeout(() => {
          inputOffice.value.loading = false;
          inputOffice.value.options = inputOffice.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputOffice.value.options.length) {
            ApiService.query(`/api/offices/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.offices.forEach((o) => {
                store.commit("addOffice", o);
              });
              inputOffice.value.list = officeData.value;
              inputOffice.value.options = data.offices;
            });
          }
        }, 200);
      } else {
        inputOffice.value.options = officeData.value;
      }
    };

    watch(
      () => officeData.value,
      (first) => {
        inputOffice.value.list = first;
        inputOffice.value.options = first;
      },
      { deep: true }
    );

    watch(
      () => props.datas,
      (first) => {
        if (first) {
          inputOffice.value.list = props.datas;
          inputOffice.value.options = props.datas;
        }
      },
      { deep: true }
    );

    watch(
      () => props.office_id,
      (first) => {
        element_id.value = first;
        console.log("first", first);
        if (first) {
          const temp = officeData.value.find((x) => x.id === first);
          if (temp) {
            emit("changeOffice", temp.name);
          }
        }
      }
    );

    onMounted(() => {
      store.commit("resetOfficeSelectModule");
      store.commit("emptyOffices");
      if (props.mounted) {
        getOffices();
      } else {
        inputOffice.value.list = officeData.value;
        inputOffice.value.options = officeData.value;
      }
      inputOffice.value.list = props.datas;
      inputOffice.value.options = props.datas;
      setTimeout(() => {
        element_id.value = props.office_id;
      }, 500);
    });

    return {
      element_id,
      inputOffice,
      errorMessage,
      meta,
      getOffices,
      selectOffice,
    };
  },
};
