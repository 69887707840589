
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { emitError } from "vue-json-pretty/types/utils";

export default {
  name: "TemplateSelect",
  props: {
    template_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  emits: ["updateId"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref(undefined) as any;
    const inputTemplate = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const getTemplate = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/template/type_templates").then(({ data }) => {
        inputTemplate.value.list = data;
        inputTemplate.value.options = data;
      });
    };

    const selectTemplate = (query) => {
      if (query !== "") {
        inputTemplate.value.loading = true;
        setTimeout(() => {
          inputTemplate.value.loading = false;
          inputTemplate.value.options = inputTemplate.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputTemplate.value.options = inputTemplate.value.list;
      }
    };

    const updateId = (id) => {
      const index = inputTemplate.value.options.findIndex(
        (x: any) => x.id === id
      );
      if (index !== -1) {
        const element = inputTemplate.value.options[index];
        emit("updateId", element);
      }
    };

    watch(
      () => props.template_id,
      (first) => {
        element_id.value = first;
      },
      { deep: true }
    );

    onMounted(() => {
      getTemplate();
      element_id.value = props.template_id;
    });

    return {
      element_id,
      inputTemplate,
      updateId,
      selectTemplate,
    };
  },
};
